
import React, { useState, useEffect } from "react";
import axios from 'axios';

import {
    App,
    Input,
    Modal,
    Form as FormAntd,
    Tabs,
    Button,
    Flex,
} from 'antd'; // Import Table from antd

// utils
import {BASE_URL} from 'utils/constants';

import {
    useQuery,
    useQueryClient,
    useMutation,
} from '@tanstack/react-query'
import { JsonSchemaItem, VehicleItem, VehicleJsonConfig } from "utils/interfaces";

import Form from '@rjsf/antd';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

interface Props {
    vehicle_name: string;
    schema_id: number,
    config_id: number,
    showModal: boolean,
    onClose: () => void
}

type VehicleJsonConfigResponse = {
    success: boolean,
    content: VehicleJsonConfig
}

type JsonSchemaResponse = {
    success: boolean,
    content: JsonSchemaItem
}

const downloadConfigBinary = (vehicleName: string, configId: number) => {
    axios({
        url: `${BASE_URL}/get_vehicle_binary/${configId}`, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        const filename = "CANNY_CONFIG_" + vehicleName + ".bin"

        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}

const EditVehicleConfigModal: React.FC<Props> = ({ vehicle_name, schema_id, config_id, showModal, onClose }: Props) => {

    const { modal } = App.useApp();

    const [form] = FormAntd.useForm<VehicleItem>();

    const [jsonConfigData, setJsonConfigData] = useState<string>()
    const [jsonValid, setJsonValid] = useState<boolean>(true)

    const vehicleJsonConfigQuery = useQuery({
        queryKey: ['vehicleJsonConfig', config_id, schema_id],
        queryFn: async () => {
            const res = await axios.get<VehicleJsonConfigResponse>(`${BASE_URL}/get_json_config/${config_id}`)
            return res.data.content
        },
        enabled: schema_id !== 0 && config_id !== 0
    })

    const jsonSchemaQuery = useQuery({
        queryKey: ['jsonSchemaConfig', config_id, schema_id],
        queryFn: async () => {
            const res = await axios.get<JsonSchemaResponse>(`${BASE_URL}/get_json_schemas_base/${schema_id}`)
            return res.data.content
        },
        enabled: schema_id !== 0 && config_id !== 0
    })

    useEffect(() => {

        setJsonConfigData(JSON.stringify(vehicleJsonConfigQuery.data?.json_config, undefined, 4))

    }, [vehicleJsonConfigQuery])

    const queryClient = useQueryClient()

    const updateVehicleMutation = useMutation({
        mutationFn: (values: VehicleItem) => {
            return axios.post(`${BASE_URL}/update_json_config/${config_id}`, values, {
                headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
              }})
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['vehicleList'] }),
      })

    const onFinish = (values: VehicleItem) => {
        console.log('Received values of form: ', JSON.parse(values.json_config as string));
        values.json_config = JSON.parse(values.json_config as string)
        // var formData = new FormData;
        // formData.append('json_config', JSON.parse(values.json_config))
        updateVehicleMutation.mutate(values, {
        onSuccess: () =>  onClose(),
        onError: (error) => {
            modal.error({
                title: 'Error adding vehicle',
                content: error.message,
            });
        }
        });
    };

    const configType = `${vehicleJsonConfigQuery.data && vehicleJsonConfigQuery.data.status}`
    const jsonSchemaName = `${jsonSchemaQuery.data && jsonSchemaQuery.data.version_schema_name}`

    const getModalTitle = () => {

        return (<>
            <Flex justify="space-around">
                {`Edit JSON config | ${vehicle_name} | ${configType} | ${jsonSchemaName}`}
                <Button onClick={() => downloadConfigBinary(vehicle_name, config_id)}>Download binary</Button>
            </Flex>
        </>)
    }

    return(
    <Modal
        title={getModalTitle()}
        loading={vehicleJsonConfigQuery.isLoading && jsonSchemaQuery.isLoading}
        open={showModal}
        okText={jsonValid ? "Update" : "JSON invalid"}
        onOk={() => {
            form.submit()
        }}
        cancelText="Cancel"
        width={1000}
        okButtonProps={{
          autoFocus: false,
          htmlType: 'submit',
          disabled: !jsonValid,
          danger: !jsonValid
        }}
        onCancel={onClose}
        destroyOnClose
        >
        <Tabs>
            <Tabs.TabPane tab="JSON Config Control" key='general' forceRender>
                <Form
                    schema={jsonSchemaQuery.data?.json_schema ? jsonSchemaQuery.data?.json_schema : {} as RJSFSchema}
                    validator={validator}
                    formData={vehicleJsonConfigQuery.data?.json_config ? vehicleJsonConfigQuery.data?.json_config : {}}
                    liveValidate
                    onChange={(e) => {
                        form.setFieldValue("json_config", JSON.stringify(e.formData, undefined, 4))
                        form.validateFields()
                    }}
                    uiSchema={{
                      "ui:submitButtonOptions": { norender: true },
                    }}
                    />
            </Tabs.TabPane>
            <Tabs.TabPane tab="JSON Config" key='2' forceRender>
                <FormAntd
                    layout="vertical"
                    form={form}
                    name="form_edit_json_config"
                    clearOnDestroy
                    onFinish={(values) => onFinish(values)}
                    onChange={(e) => {
                        console.log(e)
                    }}
                >
                    <FormAntd.Item
                        name="json_config"
                        initialValue={jsonConfigData}
                        rules={[
                            {
                            required: true,
                            validator: (_, value: string) =>{
                                    if(value) {
                                        try {
                                            JSON.parse(value);
                                            setJsonValid(true)
                                            return Promise.resolve()
                                        } catch (e) {
                                            setJsonValid(false)
                                            return Promise.reject(new Error('Invalid JSON'))
                                        }
                                    } else {
                                        setJsonValid(false)
                                        return Promise.reject(new Error('Please enter JSON Schema'))
                                    }
                                }
                            },
                        ]}>
                        <Input.TextArea rows={30} value={jsonConfigData} onChange={(e) => {
                            setJsonConfigData(JSON.stringify(e.target.value))
                        }}/>
                    </FormAntd.Item>
                </FormAntd>
            </Tabs.TabPane>
        </Tabs>
    </Modal>)
}
export default EditVehicleConfigModal;

