import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider, theme } from 'antd';
import LoginPage from 'Pages/LoginPage';
import MainPage from 'Pages/MainPage';
import RolesPage from 'Pages/RolesPage'
import UsersPage from 'Pages/UsersPage'
import CannyListPage from 'Pages/CannyListPage'
import FrimwareManager from 'Pages/FirmwareManagerPage';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import Header from "./Components/Header";
import {Footer} from "antd/lib/layout/layout";

const queryClient = new QueryClient()

const CannyWebApp: React.FC = () => (
  <ConfigProvider
    theme={{
      // 1. Use dark algorithm
      // algorithm: theme.darkAlgorithm,

      // 2. Combine dark algorithm and compact algorithm
      algorithm: [theme.defaultAlgorithm, theme.compactAlgorithm],
    }}
  >

    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Header></Header>
        <Routes>
          <Route path = "/" element = {<MainPage/>} />
          <Route path = "/login" element = {<LoginPage/>} />
          <Route path = "/role_management" element = {<RolesPage/>} />
          <Route path = "/user" element = {<UsersPage/>} />
          <Route path = "/canny" element = {<CannyListPage/>} />
          <Route path = "/firmware_manager" element = {<FrimwareManager/>} />
        </Routes>
        <Footer style={{textAlign: 'center'}}>LazerLamps Ltd</Footer>
      </BrowserRouter>
    </QueryClientProvider>
  </ConfigProvider>
);

export default CannyWebApp;